.templatetwo-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 60px 20px 60px;
    background: rgb(255, 255, 255);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999999;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.templatetwo-navlogo {
    display: flex;
    gap: 10px;
    color: rgb(21, 101, 192);
}
.templatetwo-navlogo img {
    cursor: pointer;
}
h6 {
    color: rgb(215, 60, 13);
    font-size: 18px;
}
.templatetwo-navlinks {
    flex-wrap: wrap;
}
.templatetwo-navlinks a {
    color: black;
    text-decoration: none;
    padding: 10px;
    margin: 0 10px;
    border-radius: 5px;
    transition: 0.3s;
    font-size: 18px;
}
.templatetwo-navlinks a:hover {
    color: rgb(21, 101, 192);
}
.templatetwo-navbutton-c {
    background-color: rgb(21, 101, 192);
    text-decoration: none;
    padding: 10px 25px 10px 25px;
    transition: 0.3s;
    color: white;
    border: 2px solid  transparent;
    cursor: pointer;
    transition: 0.3s;
}
.templatetwo-navbutton-c:hover {
    color: rgb(21, 101, 192);
    background-color: white;
    border: 2px solid  rgb(21, 101, 192);
}
.submit-form {
    margin-top: 20px;
}
.templatetwo-header {
    background-color: #F4F7FA;
    display: flex;
    margin-top: 88px;
    padding: 80px 0 80px 0;
}
.itemplatetwo-img-c {
    width: 40vw;
    height: 30vw;
    border-radius: 15vw 0 0 15vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-image: url("https://imageio.forbes.com/specials-images/imageserve/642bc7aea9b99c12f3deb166/0x0.jpg?format=jpg&height=900&width=1600&fit=bounds");
}
.templatetwo-header-h2 {
    text-align: left;
    font-weight: 300;
    font-size: 4vw;
}
.templatetwo-header-textarea {
    padding-left: 10vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.templatetwo-header-button {
    background-color: rgb(21, 101, 192);
    text-decoration: none;
    padding: 10px 25px 10px 25px;
    transition: 0.3s;
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.3s;
}
.templatetwo-header-button:hover {
    color: rgb(21, 101, 192);
    background-color: white;
    outline: 2px solid  rgb(21, 101, 192);
}
.indents {
    margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
}

.template-two-services {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.read-more-button {
    background-color: rgb(21, 101, 192);
    text-decoration: none;
    padding: 10px 25px 10px 25px;
    transition: 0.3s;
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    width: 170px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 25px;
}
.read-more-button:hover {
    color: rgb(21, 101, 192);
    background-color: white;
    outline: 2px solid  rgb(21, 101, 192);
}
.templatetwo-section-subtext {
    font-size: 1rem;
    width: 35vw;
    margin: auto;
}
.template-two-services-tabs {
    width: 70%;
}
.template-two-services-list {
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0 20px 0;
}

.loading-screen {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    z-index: 9999999999;
}

.dev-ops {
    display: flex;
    gap: 25px;
}
.service-item .black li {
    color: white;
    text-align: left;
}
.service-item li {
    list-style: none;
    font-weight: 400;
    font-size: 18px;
    transition: 0.8s;
    color: #393e48;
    display: flex;
    gap: 12px;
    margin-top: 12px;
    align-items: center;
}
.service-item {
    flex: 1;
    width: 400px !important;
    font-weight: 500;
    font-size: 18px;
    color: #393e48;
    transition: 0.3s;
}
.service-item p {
    font-weight: 500;
    font-size: 18px;
    transition: 0.3s;
    color: #393e48;
}
.tabs {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 15px;
}
.tab {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    color: #282c34;
    transition: 0.3s;
}
.service-image {
    flex: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 400px;
    height: 400px;
    transition: 0.3s;
    border-radius: 12px;
}
.template-two-services-service {
    background-color: rgb(21, 101, 192);
    color: white;
    padding: 0 10px 20px 10px;
    width: 20vw;
}
.template-two-services-service h5 {
    margin-bottom: 0;
    font-weight: 500;
}
.template-two-services-service p {
    margin-top: 5px;
    color: white;
    text-align: center;
    font-weight: 300;
}
.templatetwo-history {
    background: white;
    margin: 60px;
}
.templatetwo-section-title-history {
    text-align: left;
    margin: 0;
}
.templatetwo-section-subtext-history {
    text-align: left;
    margin: 12px 0 0 0;
}
.templatetwo-history .flex-sidebyside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}
.templatetwo-history-img {
    background-image: url("https://images.unsplash.com/photo-1491904768633-2b7e3e7fede5?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-position: center;
    width: 42vw;
    height: 33vw;
}
.templatetwo-history-list {
    width: 40vw;
}
.templatetwo-history-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}
.templatetwo-history-section h2 {
    color: rgb(21, 101, 192);
}
.flex-reverse p {
    text-align: right;
}

.templatetwo-whyus {
    margin: 60px;
}
.templatetwo-section-title-whyus {
    text-align: center;
    margin-bottom: 0;
    color: #282c34;
    font-size: 35px;
}
.templatetwo-section-subtext-whyus {
    text-align: center;
    margin-top: 5px;
    font-size: 18px;
    color: #565f70;
}
.templatetwo-whyus-grid {
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    flex-wrap: wrap;
}
.templatetwo-whyus-grid-item {
    padding: 20px 10px 10px 10px;
    max-width: 18vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.templatetwo-whyus-grid-item h1 {
    text-align: center;
    margin: 0;
    font-weight: 400;
    color: black;
}
.templatetwo-whyus-grid-item p {
    border-top: 2px solid rgb(21, 101, 192);
    padding-top: 12px;
    color: #464646;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
}
.open-menu {
    display: none;
}
.menu {
    display: none;
}

@media screen and (max-width: 700px) {
    .open-menu {
        display: block;
        cursor: pointer;
    }
    .menu {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 20px;
        background: white;
        width: 100%;
        padding: 20px;
        left: 0;
    }
    .menu a {
        padding: 10px 10px;
        text-decoration: none;
        color: #282c34;
        transition: 0.2s;
    }
    .menu a:hover {
        background: #d5d5d5;
    }
    .templatetwo-nav {
        display: none;
    }
}

.templatetwo-porfolio-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
}
.templatetwo-porfolio-grid-item {
    text-align: left;
}
h6 {
    font-size: 20px;
    text-align: center;
}
.d-none {
    display: none !important;
}
.templatetwo-header-p {
    font-size: 20px;
}
.templatetwo-section-title {
    text-align: center;
}
.templatetwo-porfolio-grid-item .templatetwo-section-title {
    margin: 10px 0 5px 0;
    text-align: center;
}
.templatetwo-portfolio-image-sub {
    margin: 0;
    color: black;
    font-size: 12px;
    font-weight: 400;
}
.templatetwo-section-subtext-portfolio {
    margin-bottom: 10px;
    font-weight: 400;
    margin-top: 0;
}
.templatetwo-button-icon {
    background: rgb(21, 101, 192);
    padding: 10px 20px;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    margin: auto;
    cursor: pointer;
    transition: 0.3s;
}
.templatetwo-button-icon:hover {
    color: rgb(21, 101, 192);
    background-color: white;
    outline: 2px solid  rgb(21, 101, 192);
}

.templatetwo-company-numbers {
    margin: 25px;
}
.templatetwo-company-numbers-header {
    text-align: left;
}

.templatetwo-company-numbers .flex-center-onerow {
    display: flex;
    justify-content: space-between;
}
.templatetwo-company-numbers-item {
    width: 30%;
    text-align: left;
}
.templatetwo-company-numbers-item h1 {
    text-align: left;
    font-weight: 400;
    color: rgb(21, 101, 192);
}
.templatetwo-testimonials {
    padding: 40px 0 40px;
    background: #fafafa;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}
.templatetwo-testimonial {
    padding: 40px;
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    z-index: 0;
    max-width: 800px;
    color: white;
    background: rgb(21, 101, 192);
}
.quites {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.templatetwo-testemonials-item-content {
    text-align: center;
    border-radius: 12px;
}
.templatetwo-testemonials-item-content p {
    text-align: center;
    color: white;
    margin: 20px 0 20px 0;
    font-size: 18px;
}
.username {
    font-size: 19px;
    font-weight: 500;
    color: #033f4f;
}
.templatetwo-testemonials-item-content h2 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 300;
}
.templatetwo-location {
    display: flex;
    gap: 15px;
    margin: 60px;
    justify-content: center;
}
.templatetwo-location-info {
    text-align: end;
}
.templatetwo-location-info p {
    text-align: end;
}
.color-picker {
    background-color: white;
    z-index: 99999;
}
.templatetwo-footer {
    display: flex;
    gap: 30px;
    padding: 30px 30px 30px 30px;
    color: white;
    align-items: center;
    justify-content: center;

    background-color: rgb(21, 101, 192);
}
.text-flex {
    display: flex;
    gap: 20px;
}
@media screen and (max-width: 700px) {
    .service-image {
        display: none;
    }
    .itemplatetwo-img-c {
        display: none;
    }
    .templatetwo-header-h2 {
        font-size: 28px;
    }
}
.templatetwo-footer-flex-one {
    width: 30%;
}
.templatetwo-footer-flex-one h2 {
    text-align: left;
}
.templatetwo-footer-flex-one p {
    text-align: left;
    color: white;
    font-size: 14px;
    font-weight: 300;
}
.templatetwo-footer-flex-two {
    display: flex;
    gap: 12px;
}
.templatetwo-footer-flex-two-col a {
    color: white;
    text-decoration: none;
    font-weight: 300;
    font-size: 14px;
}
.templatetwo-footer-flex-two-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}
.templatetwo-footer-flex-three {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.templatetwo-footer-flex-three a {
    color: white;
    font-size: 18px;
}
.portfolio-popup {
    position: fixed;
    z-index: 99999999999;
    background: #F4F7FA;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 50px;
}
.close {
    cursor: pointer;
    color: black;
    background: #e8e8e8;
    padding: 10px;
    transition: 0.3s;
}
.close:hover {
    cursor: pointer;
    color: rgb(21, 101, 192);
    background: #ececec;
    padding: 10px;
}
.portfolio-popup-text {
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    gap: 25px;
    align-items: center;
}
.portfolio-text {
    width: 40%;
    text-align: left;
}
.portfolio-text h1 {
    text-align: left;
    font-size: 40px;
    font-weight: 300;
    color: rgb(21, 101, 192);
}
.portfolio-text p {
    text-align: left;
    font-size: 18px;
}
.portfolio-img {
    width: 50%;
    height: 70vh;
    background-position: center;
    background-size: cover;
}
.portfolio-switcher {
    display: flex;
    gap: 15px;
}
.portfolio-arrow {
    background: #e8e8e8;
    border-radius: 50%;
    cursor: pointer;
    padding: 12px;
    color: rgb(21, 101, 192);
    transition: 0.3s;
}
.portfolio-arrow:hover {
    color: white;
    background: rgb(21, 101, 192);
}
.templatetwo-Contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fafafa;
    width: 100%;
    height: 100vh;
    border-radius: 12px ;
}
.templatetwo-Contact h1 {
    font-weight: 400;
    margin-top: -50px;
    font-size: 35px;
}
.why-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-image: url("https://imageio.forbes.com/specials-images/imageserve/642bc7aea9b99c12f3deb166/0x0.jpg?format=jpg&height=900&width=1600&fit=bounds");
    min-width: 23vw;
    border-radius: 12px 0 0 12px;
}
.why-contact-text {
    border-radius: 12px 0 0 12px;
    background: rgba(0, 0, 0, 0.64);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: white;
}
.success-page-template-two {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.why-contact-text h2 {
    margin-top: 40px;
}
.why-contact-text p {
    width: 70%;
}
.sidebyside {
    display: flex;
    background: #F4F7FA;
    gap: 20px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
}
.contact-form h4 {
    font-size: 20px;
    font-weight: 300;
}
.contact-form {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px 40px 20px ;
}

@media screen and (max-width: 1200px) {
    .why-contact {
        display: none;
    }
    .contact-form {
        min-width: auto;
    }
}
.templateTwo-services-page {
    padding: 80px 40px;
}

.services-page-content-item {
    display: flex;
}
.templateTwo-services-page-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    flex-wrap: wrap;
    width: fit-content;
    gap: 55px;
    padding: 0 100px;
}
.templateTwo-services-page-header {
    text-align: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-item-text {
    border: 1px solid rgba(2, 2, 2, 0.11);
    color: rgb(10, 49, 93);
    padding: 30px;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    border-radius: 12px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.08), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
}
.content-item-text li {
    padding: 12px 0;
}

@media screen and (max-width: 1227px) {
    .templateTwo-services-page-content {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        padding: 0;
    }
    .content-item-text {
        width: 300px;
    }

}